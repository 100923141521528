import styled from 'styled-components';
import { COLORS } from '../../constants.styles';

export const ColumnHeading = styled.div`
  text-align: ${props => (props.center ? 'center' : 'start')};
  color: black;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 20px;
`;

export const ColumnDescription = styled.div`
  color: ${COLORS.DARK_GREY};
`;
