import styled from 'styled-components';

export const TimelineContainer = styled.div`
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
`;

export const NonMobileMargin = styled.div`
  margin-left: 200px;
  margin-right: 200px;
  @media (max-width: 768px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const CareersContainer = styled.div`
  text-align: center;
`;
