import React from 'react';
import PropTypes from 'prop-types';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import { ColumnDescription, ColumnHeading } from './Timeline.styled';

const TimelineBox = ({ TimelineBoxData }) => {
  const { heading, description, year, icon } = TimelineBoxData.primary;
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element"
      date={year.text}
      icon={<img src={icon.url} alt="icon" />}
    >
      <ColumnHeading>{heading.text}</ColumnHeading>
      <ColumnDescription>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: description.html }}
        />
      </ColumnDescription>
    </VerticalTimelineElement>
  );
};

TimelineBox.propTypes = {
  TimelineBoxData: PropTypes.shape({
    primary: PropTypes.shape({
      heading: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
      description: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }),
      year: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
      icon: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }),
    }).isRequired,
  }).isRequired,
};

export default TimelineBox;
