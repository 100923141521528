import React from 'react';
import { get } from 'lodash';
import { graphql } from 'gatsby';

import Page from '../layout/Page';
import Header from '../components/common/Header';
import Paragraph from '../components/common/Paragraph';
import Section from '../components/common/Section';
import Customers from '../components/common/Customers';

import { Timeline } from '../components/Timeline';

import { TimelineContainer, NonMobileMargin, CareersContainer } from '../page-styles/qui-sommes-nous.styled';
import { ContactButton } from '../components/index.styled';
import { COLORS } from '../constants.styles';

export default ({ data }) => {
  const timelineData = get(data, 'prismicQuiSommesNousPage.data.link_to_timeline.document.data');
  const customers = data.allPrismicCustomers.edges[0].node.data.body;

  return (
    <Page>
      <Section>
        <div className="container">
          <Header>{get(data, 'prismicQuiSommesNousPage.data.heading.text')}</Header>
          <Paragraph>{get(data, 'prismicQuiSommesNousPage.data.description.text')}</Paragraph>
        </div>
      </Section>
      <Section theme={COLORS.LIGHT_GREY}>
        <TimelineContainer>
          <Timeline timelineData={timelineData} />
        </TimelineContainer>
      </Section>
      <Section>
        <CareersContainer className="container">
          <Header>{get(data, 'prismicQuiSommesNousPage.data.careers_heading.text')}</Header>
          <Paragraph>{get(data, 'prismicQuiSommesNousPage.data.careers_description.text')}</Paragraph>
          <ContactButton
            href={get(data, 'prismicQuiSommesNousPage.data.careers_link.text')}
            className="btn btn-info"
            role="button"
          >
            {get(data, 'prismicQuiSommesNousPage.data.careers_link_text.text')}
          </ContactButton>
        </CareersContainer>
      </Section>
      <Section>
        <NonMobileMargin>
          <h1>{data.allPrismicCustomers.edges[0].node.data.title.text}</h1>
          <Customers logos={customers} />
        </NonMobileMargin>
      </Section>
    </Page>
  );
};

export const query = graphql`
  query {
    prismicQuiSommesNousPage {
      data {
        link_to_timeline {
          id
          document {
            ... on PrismicTimeline {
              data {
                body {
                  ... on PrismicTimelineBodyTimelineBox {
                    primary {
                      year {
                        text
                      }
                      heading {
                        text
                      }
                      description {
                        html
                      }
                      icon {
                        url
                        dimensions {
                          width
                          height
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        heading {
          text
        }
        description {
          text
        }
        customer_list {
          id
        }
        careers_heading {
          text
        }
        careers_description {
          text
        }
        careers_link {
          text
        }
        careers_link_text {
          text
        }
      }
    }
    allPrismicCustomers {
      edges {
        node {
          id
          data {
            title {
              text
            }
            body {
              __typename
              ... on PrismicCustomersBodyCustomer {
                primary {
                  logo {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
