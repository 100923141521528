import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, ImageWrapper, Image } from './Customers.styled';

const Customers = props => {
  const { logos } = props;
  return (
    <Wrapper>
      {logos.map(imageSource => (
        <ImageWrapper key={imageSource.primary.logo.url}>
          <Image src={imageSource.primary.logo.url} />
        </ImageWrapper>
      ))}
    </Wrapper>
  );
};

Customers.propTypes = {
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ).isRequired,
};

export default Customers;
