import React from 'react';
import { VerticalTimeline } from 'react-vertical-timeline-component';

import TimelineBox from './TimelineBox';

const Timeline = ({ timelineData }) => {
  return (
    <VerticalTimeline layout="1-column">
      {timelineData.body.map((TimelineBoxData, index) => (
        <TimelineBox key={index} TimelineBoxData={TimelineBoxData} />
      ))}
    </VerticalTimeline>
  );
};

export default Timeline;
